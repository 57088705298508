function fromBase64(input) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let str = input.replace(/=+$/, '');
  let output = '';

  if (str.length % 4 == 1) {
    throw new Error(
      '"fromBase64" falhou: A string não está codificada corretamente em base64.',
    );
  }

  for (
    let bc = 0, bs, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }

  return output;
}

export const decryptData = (encryptedData) => {
  try {
    const secretKey = process.env.VUE_APP_DECRYPT_DATA;
    const decodedData = fromBase64(encryptedData);

    let decryptedData = '';
    for (let i = 0; i < decodedData.length; i++) {
      let charCode = decodedData.charCodeAt(i) ^ secretKey;
      decryptedData += String.fromCharCode(charCode);
    }
    return JSON.parse(decryptedData);
  } catch (error) {
    // Assumindo que o erro ocorreu porque os dados não estavam criptografados
    return encryptedData; // Retornar os dados originais se a decodificação falhar
  }
};
