<template>
  <div class="topoInterno">
    <div class="topo" :style="`background-color:${temaCor.topoApp}`">
      <b-row>
        <b-col>
          <!--b-button variant="link text-dark">
          <font-awesome-icon :icon="['fa', 'question-circle']" />
        </b-button-->
        </b-col>
        <b-col cols="8">
          <h3 class="p-0 text-center">{{ titulo }}</h3>
        </b-col>
        <b-col class="text-right">
          <div @click="openMenuLateral()" :class="`icone mb-2 `">
            <font-awesome-icon :icon="['fa', 'bars']" class="text-secondary" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import colors from "./../services/colors";
export default {
  name: "DashBoard",
  props: {
    titulo: String,
  },
  data() {
    return {
      value: 80,
      max: 100,
      temaCor: {},
    };
  },
  methods: {
    openMenuLateral() {
      this.$emit("OpenMenuLateral", true);
    },
    openDecoded() {
      this.temaCor = colors.getColorsTema();
      console.log(this.temaCor);
      this.logo = localStorage.getItem("logo");
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
      }
    },
    closeMenu() {
      this.$router.push("/dashboard");
    },
  },
  mounted() {
    this.openDecoded();
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.topoInterno {
  background-color: #e9e9ea;
}
.topo {
  font-size: 11px;
  height: 80px;
  padding: 10px;
  .icone {
    vertical-align: middle;
    font-size: 28px;
    margin-right: 20px;
    margin-top: 10px;
  }
}

h3 {
  color: #fff;
  margin-top: 22px;
  text-transform: uppercase;
  font-size: 18px;
}
</style>
