<template>
  <div v-if="show == true" class="carregando">
    <br />
    <br />
    <br />Carregando... Aguarde
  </div>
</template>

<script>
import { LOGO_WHITE } from './../shared/global';

export default {
  name: 'loading',
  props: {
    show: Boolean,
  },
  data() {
    return {
      logo: LOGO_WHITE,
    };
  },
};
</script>

<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}
</style>
