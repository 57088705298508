const LOGO = 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png';
const LOGO_WHITE = 'https://bboneapp.s3.amazonaws.com/evo-white.png';
const SMS = 'SMS';
const WHATSAPP = 'WHATSAPP';

module.exports = {
  LOGO,
  LOGO_WHITE,
  SMS,
  WHATSAPP,
};
